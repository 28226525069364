export const COPY_OPTIONS = {
  css: "css",
  js: "js",
  value: "value",
};

export const initialSettings = {
  copyOption: COPY_OPTIONS.css,
  layerColor: `linear-gradient(122deg, rgb(89 102 255 / 40%), transparent)`,
  controlSettings: {
    width: {
      unit: "px",
      max: 500,
      min: 25,
    },
    height: {
      unit: "px",
      max: 500,
      min: 25,
    },
    borderRadius: {
      unit: "px",
      min: 0,
      max: 100,
    },
    margin: {
      unit: "px",
      min: 5,
      max: 75,
    },
  },
  style: {
    borderRadius: "10px",
    width: "150px",
    height: "150px",
    backgroundColor: "white",
    margin: "50px",
  },
};

export const LAYER_COLORS = {
  white: "#fff",
  charcoal: "darkgray",
  red: `red`,
  orange: `orange`,
  yellow: `yellow`,
  green: `green`,
  blue: `blue`,
  purple: `purple`,
  pink: `pink`,
  cyan: `cyan`,
};

export const LAYER_GRADIENT_COLORS = {
  transparent: "transparent",
  default: `${initialSettings.layerColor}`,
  orange: `linear-gradient(122deg, rgb(255 110 0 / 40%), transparent)`,
  green: `linear-gradient(122deg, rgb(102 255 0 / 40%), transparent)`,
  blue: `linear-gradient(122deg, rgb(0 247 255 / 40%), transparent)`,
  purple: `linear-gradient(122deg, rgb(169 0 255 / 40%), transparent)`,
  pink: `linear-gradient(122deg, rgb(255 0 214 / 40%), transparent)`,
  red: `linear-gradient(122deg, rgb(255 0 0 / 40%), transparent)`,
  yellow: `linear-gradient(122deg, rgb(255 254 0 / 40%), transparent)`,
  charcoal: "linear-gradient(122deg, rgb(0 0 0 / 40%), transparent)",
};
