import { useEffect, useState } from "react";
import { shadows } from "../../assets/shadows";
import { LayerInspector } from "./components/LayerInspector/LayerInspector";
import { ShadowLayer } from "./components/ShadowLayer";
import "./ShadowLayerViewer.css";

export const ShadowLayerViewer = ({
  selectedId,
  selectedValue,
  layerColor,
  setLayers,
}) => {
  const { value, author } = shadows[selectedId - 1];
  const [offsetAmount, setOffsetAmount] = useState(35);

  const layers = value
    .replaceAll("box-shadow: ", "")
    .replaceAll(";", "")
    .split(", rgb")
    .map((s, i) => {
      if (i === 0) return s;
      return `rgb${s}`;
    });

  const oneVal = layers.length === 1;
  const offsetCalc = oneVal
    ? 0
    : Math.round((layers.length * offsetAmount) / 2);
  const windowOffsetTransform = `translate(-${offsetCalc}px, -${offsetCalc}px)`;

  useEffect(() => {
    setLayers(layers);
  }, [selectedId]);
  return (
    <>
      <div className="shadow-layer-viewer">
        <div
          className="shadow-viewer-render-window"
          style={layers.length > 1 ? { transform: windowOffsetTransform } : {}}
        >
          {layers.map((shadow, index) => (
            <ShadowLayer
              offsetAmount={offsetAmount}
              boxShadow={shadow}
              layerId={index + 1}
              isSingleShadow={oneVal}
              layerColor={layerColor}
            />
          ))}
        </div>
      </div>
      {value && <LayerInspector layers={layers} compositeValue={value} />}
    </>
  );
};
