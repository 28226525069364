import "./SelectCopyType.css";
import { Select, Tooltip } from "@mantine/core";
import { CopyAll } from "@mui/icons-material";
import { COPY_OPTIONS } from "../../../../configs";
import { useState } from "react";

export const SelectCopyType = ({ onChange, value }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  return (
    <div
      className="select-outer-container"
      onClick={() => setIsTooltipOpen(false)}
      onMouseEnter={() => setIsTooltipOpen(true)}
      onMouseLeave={() => setIsTooltipOpen(false)}
    >
      <span className="select-icon-container">
        <CopyAll sx={{ color: "lightgray" }} />
      </span>
      <Tooltip
        opened={isTooltipOpen}
        label="Select Copied Value"
        position="bottom"
        withArrow
      >
        <div
          className="select-container"
          style={{
            width: "100px",
          }}
        >
          <Select
            value={value}
            onChange={onChange}
            placeholder="Copy"
            data={[
              {
                value: COPY_OPTIONS.css,
                label: "CSS",
              },
              { value: COPY_OPTIONS.js, label: "JS" },
              { value: COPY_OPTIONS.value, label: "String" },
            ]}
          />
        </div>
      </Tooltip>
    </div>
  );
};
