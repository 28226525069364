import "./CompositeValueDisplay.css";
import { Prism } from "@mantine/prism";

export const CompositeValueDisplay = ({ value, layers }) => {
  const isEmpty = layers.length === 0;

  function CSSIcon(props) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
        <path fill="#0277BD" d="M41 5H7l3 34 14 4 14-4 3-34z" />
        <path fill="#039BE5" d="M24 8v31.9l11.2-3.2L37.7 8z" />
        <path
          fill="#FFF"
          d="M33.1 13H24v4h4.9l-.3 4H24v4h4.4l-.3 4.5-4.1 1.4v4.2l7.9-2.6.7-11.5z"
        />
        <path
          fill="#EEE"
          d="M24 13v4h-8.9l-.3-4H24zm-4.6 8l.2 4H24v-4h-4.6zm.4 6h-4l.3 5.5 7.9 2.6v-4.2l-4.1-1.4-.1-2.5z"
        />
      </svg>
    );
  }

  const JSIcon = () => (
    <img
      style={{ width: "20px", height: "20px" }}
      src={`data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0OCA0OCI+PHBhdGggZmlsbD0iI2ZmZDYwMCIgZD0iTTYsNDJWNmgzNnYzNkg2eiIvPjxwYXRoIGZpbGw9IiMwMDAwMDEiIGQ9Ik0yOS41MzggMzIuOTQ3Yy42OTIgMS4xMjQgMS40NDQgMi4yMDEgMy4wMzcgMi4yMDEgMS4zMzggMCAyLjA0LS42NjUgMi4wNC0xLjU4NSAwLTEuMTAxLS43MjYtMS40OTItMi4xOTgtMi4xMzNsLS44MDctLjM0NGMtMi4zMjktLjk4OC0zLjg3OC0yLjIyNi0zLjg3OC00Ljg0MSAwLTIuNDEgMS44NDUtNC4yNDQgNC43MjgtNC4yNDQgMi4wNTMgMCAzLjUyOC43MTEgNC41OTIgMi41NzNsLTIuNTE0IDEuNjA3Yy0uNTUzLS45ODgtMS4xNTEtMS4zNzctMi4wNzgtMS4zNzctLjk0NiAwLTEuNTQ1LjU5Ny0xLjU0NSAxLjM3NyAwIC45NjQuNiAxLjM1NCAxLjk4NSAxLjk1MWwuODA3LjM0NEMzNi40NTIgMjkuNjQ1IDM4IDMwLjgzOSAzOCAzMy41MjMgMzggMzYuNDE1IDM1LjcxNiAzOCAzMi42NSAzOGMtMi45OTkgMC00LjcwMi0xLjUwNS01LjY1LTMuMzY4TDI5LjUzOCAzMi45NDd6TTE3Ljk1MiAzMy4wMjljLjUwNi45MDYgMS4yNzUgMS42MDMgMi4zODEgMS42MDMgMS4wNTggMCAxLjY2Ny0uNDE4IDEuNjY3LTIuMDQzVjIyaDMuMzMzdjExLjEwMWMwIDMuMzY3LTEuOTUzIDQuODk5LTQuODA1IDQuODk5LTIuNTc3IDAtNC40MzctMS43NDYtNS4xOTUtMy4zNjhMMTcuOTUyIDMzLjAyOXoiLz48L3N2Zz4=`}
    />
  );
  const cssCode = isEmpty ? "-" : `box-shadow: ${layers.join(",\n\t    ")};`;
  const jsCode = isEmpty ? "-" : `boxShadow: "${layers.join(",\n\t    ")}"`;

  const CodeTabs = () => (
    <Prism.Tabs defaultValue="css" className={isEmpty ? "disable-tabs" : ""}>
      <Prism.TabsList>
        <Prism.Tab
          value="css"
          icon={<CSSIcon width={16} height={16} />}
          withLineNumbers
        >
          CSS
        </Prism.Tab>
        <Prism.Tab value="js" icon={<JSIcon />} withLineNumbers>
          JavaScript
        </Prism.Tab>
      </Prism.TabsList>
      <Prism.Panel language="css" value="css">
        {cssCode}
      </Prism.Panel>
      <Prism.Panel language="js" value="js">
        {jsCode}
      </Prism.Panel>
    </Prism.Tabs>
  );
  return (
    <div className="layer-inspector-composite-container">
      {/* <Prism language="css" sx={{ overflow: "hidden" }} withLineNumbers>
        {code}
      </Prism> */}
      <CodeTabs />
    </div>
  );
};
