import "./SelectLayerColor.css";
import {
  initialSettings,
  LAYER_COLORS,
  LAYER_GRADIENT_COLORS,
} from "../../../../configs";
//SelectLayerColor
import {
  Menu,
  Button,
  Text,
  Tooltip,
  Divider,
  ColorInput,
  CopyButton,
} from "@mantine/core";
import { useState } from "react";
import {
  CheckCircle,
  ContentCopy,
  CopyAll,
  CopyAllOutlined,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";

export const SelectLayerColor = ({ layerColor, onChange }) => {
  const ColorButton = ({ color, onClick }) => {
    const isTransparent = color === "transparent";

    const overrides = {
      border: isTransparent ? "2px dashed #dedede" : "",
    };
    return (
      <span
        onClick={onClick}
        className="color-swatch-button"
        style={{ background: color, ...overrides }}
      ></span>
    );
  };

  const renderColorsObj = (obj) => {
    const handleClick = (val) => {
      setCustom("");
      onChange(val);
    };
    return (
      <>
        {Object.keys(obj).map((color) => {
          const _val = obj[color];
          return (
            <ColorButton
              key={_val}
              onClick={() => handleClick(_val)}
              color={_val}
            />
          );
        })}
      </>
    );
  };

  const [isOpen, setIsOpen] = useState(false);
  const [custom, setCustom] = useState(undefined);

  const closeMenu = () => setIsOpen(false);
  const handleChangeCustom = (val) => {
    if (!val || val.length < 7) {
      onChange("transparent");
      setCustom("");
    } else {
      const trim = val.trim().slice(0, 7);
      onChange(trim);
      setCustom(trim);
    }
    setIsOpen(true);
  };
  return (
    <Menu opened={isOpen} shadow="md" width={200} onClose={closeMenu}>
      <Menu.Target>
        <Tooltip
          label="Select Layer Color"
          position="bottom"
          withArrow
          offset={15}
        >
          <span
            onClick={() => setIsOpen((prev) => !prev)}
            style={{ marginLeft: "10px" }}
          >
            <ColorButton color={layerColor} />
          </span>
        </Tooltip>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label sx={{ userSelect: "none" }}>Layer Color</Menu.Label>
        {/* <Menu.Item
          icon={<ColorButton color={layerColor} />}
          onClick={() => onChange(initialSettings.layerColor)}
        ></Menu.Item> */}
        <div className="layer-color-options">
          {renderColorsObj(LAYER_GRADIENT_COLORS)}
        </div>
        <Menu.Divider />
        <div className="layer-color-options">
          {renderColorsObj(LAYER_COLORS)}
        </div>
        <Menu.Divider />
        <div className="layer-color-custom-container">
          <ColorInput
            onBlur={closeMenu}
            value={custom}
            onChange={handleChangeCustom}
            placeholder="Custom"
          />
          <CopyButton value={custom}>
            {({ copied, copy }) => (
              <Tooltip
                color={copied ? "green" : ""}
                label={copied ? "Copied!" : "Copy Color"}
                position="bottom"
                withArrow
              >
                <IconButton
                  onClick={copy}
                  disabled={!custom}
                  sx={{ marginLeft: "5px" }}
                >
                  {copied ? (
                    <CheckCircle sx={{ color: "#40c057" }} />
                  ) : (
                    <ContentCopy
                      sx={{
                        filter: custom
                          ? "drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.12))"
                          : "",
                        color: custom ? custom : "lightgray",
                      }}
                    />
                  )}
                </IconButton>
              </Tooltip>
            )}
          </CopyButton>
        </div>
      </Menu.Dropdown>
    </Menu>
  );
};
