import { Tooltip, Text } from "@mantine/core";
import { useState } from "react";
import "./ShadowPreviewBox.css";
import ContentCopy from "@mui/icons-material/ContentCopy";
import CheckCircle from "@mui/icons-material/CheckCircle";
import { COPY_OPTIONS } from "../../configs";
import { useClipboard } from "@mantine/hooks";

export const ShadowPreviewBox = ({
  styleOverrides = {},
  author,
  id,
  onSelect,
  selected,
  settings,
  boxShadowValue,
}) => {
  const [clicked, setClicked] = useState(false);
  const clipboard = useClipboard({ timeout: 500 });

  const style = {
    ...styleOverrides,
    margin: 0,
  };

  const copyShadow = () => {
    let str = "";
    switch (settings.copyOption) {
      case COPY_OPTIONS.js: {
        str = "boxShadow: " + `"${boxShadowValue}"`;
        break;
      }
      case COPY_OPTIONS.css: {
        str = "box-shadow: " + boxShadowValue + ";";
        break;
      }
      case COPY_OPTIONS.value: {
        str = boxShadowValue;
        break;
      }
    }
    clipboard.copy(str);
  };

  const handleClick = () => {
    setClicked(true);
    onSelect(id);
    copyShadow();
  };
  const getOptionText = () => {
    switch (settings.copyOption) {
      case COPY_OPTIONS.js:
      case COPY_OPTIONS.css: {
        return settings.copyOption.toUpperCase();
      }
      case COPY_OPTIONS.value: {
        return "shadow value";
      }
    }
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      setClicked(false);
    }, 500);
  };
  return (
    <span
      className="shadow-option-container"
      style={{ padding: styleOverrides.margin }}
    >
      <span>
        <Tooltip
          zIndex={3}
          label={
            clicked ? (
              <Text sx={{ display: "flex", alignItems: "center" }}>
                <CheckCircle sx={{ fontSize: "12px", marginRight: "10px" }} />
                Copied {getOptionText()}!
              </Text>
            ) : (
              <Text sx={{ display: "flex", alignItems: "center" }}>
                <ContentCopy sx={{ fontSize: "12px", marginRight: "10px" }} />
                Click to copy
              </Text>
            )
          }
          color={clicked ? "green" : ""}
          style={{
            userSelect: "none",
          }}
          withArrow
        >
          <span
            onClick={handleClick}
            onMouseLeave={handleMouseLeave}
            className={`shadow-option ${selected ? "is-active" : ""}`}
            style={style}
          >
            {selected && (
              <span className="shadow-option-active">
                <Text
                  size="xs"
                  className="text"
                  color="green"
                  sx={{ userSelect: "none" }}
                >
                  Active Shadow
                </Text>
              </span>
            )}
          </span>
        </Tooltip>
      </span>
    </span>
  );
};
