import "./ControlPanel.css";
import { Accordion } from "@mantine/core";
import { Slider } from "@mantine/core";
import { TextInput } from "@mantine/core";
export const ControlPanel = ({
  label,
  value,
  onChange,
  keyProp,
  settings,
  isDefault,
}) => {
  const { unit, min, max } = settings;
  const handleChange = (val) => {
    const newSettings = val + unit;
    onChange("style", newSettings, keyProp);
  };
  return (
    <div className="control-panel-contents">
      <Slider
        className="control-panel-slider"
        color={isDefault ? "gray" : "green"}
        min={min}
        max={max}
        value={Number(value.slice(0, value.length - 2))}
        onChange={handleChange}
        style={{ width: "100px", opacity: isDefault ? 0.5 : 1 }}
      />
      <TextInput
        disabled
        style={{
          width: "70px",
          marginLeft: "10px",
        }}
        size="xs"
        value={value}
      ></TextInput>
    </div>
  );
};
