import "./SelectPreviewModifiers.css";
import {
  Text,
  Tooltip,
  Popover,
  Accordion,
  Divider,
  Indicator,
} from "@mantine/core";
import { ListAltRounded } from "@mui/icons-material";
import { useState } from "react";
import { ControlPanel } from "../../../ControlPanel/ControlPanel";
import { initialSettings } from "../../../../configs";
import { Badge } from "@mui/material";

export const SelectPreviewModifiers = ({
  onChange,
  settings,
  onResetAll,
  modified,
  margin,
  width,
  height,
  borderRadius,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    if (isOpen) return;
    setShowTooltip(true);
  };
  const handleMouseLeave = () => {
    if (isOpen) return;
    setShowTooltip(false);
  };
  const handleClick = () => {
    setIsOpen((prev) => !prev);
    setShowTooltip(false);
  };

  const amountChanged = () => {
    let count = 0;

    for (const val in initialSettings.style) {
      switch (val) {
        case "width": {
          if (initialSettings.style[val] !== width) count++;
          break;
        }
        case "height": {
          if (initialSettings.style[val] !== height) count++;
          break;
        }
        case "margin": {
          if (initialSettings.style[val] !== margin) count++;
          break;
        }
        case "borderRadius": {
          if (initialSettings.style[val] !== borderRadius) count++;
          break;
        }
      }
    }
    return count;
  };
  return (
    <Popover
      position="bottom"
      withArrow
      shadow="md"
      opened={isOpen}
      offset={5}
      onClose={handleClick}
    >
      <Popover.Target>
        <Tooltip
          opened={showTooltip}
          label="Customize Previews"
          position="bottom"
          withArrow
        >
          <div
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="select-preview-modifiers-btn"
          >
            <Badge
              badgeContent={amountChanged()}
              sx={{
                "& .MuiBadge-badge": {
                  color: "white",
                  backgroundColor: "#40c057",
                  fontWeight: "bold",
                },
              }}
            >
              <ListAltRounded
                className={amountChanged() ? "is-modified" : ""}
                sx={{
                  color: isOpen || amountChanged() ? "#40c057" : "lightgray",
                }}
              />
            </Badge>
          </div>
        </Tooltip>
      </Popover.Target>
      <Popover.Dropdown>
        <div className="accordion-container">
          <Text
            align="left"
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              display: "flex",
              minHeight: "26px",
            }}
            opacity={0.5}
            size="sm"
          >
            <span style={{ marginRight: "auto", pointerEvents: "none" }}>
              Preview Box{" "}
            </span>
            {modified ? (
              <span className="reset-btn" onClick={onResetAll}>
                Reset
              </span>
            ) : (
              ""
            )}
          </Text>
          <Divider mb="8px" mt="8px" opacity={0.35} />
          <div className="modifier-row">
            <Text className="modifier-row-text" size="xs">
              Width
            </Text>
            <ControlPanel
              settings={settings.controlSettings.width}
              label={"Width"}
              keyProp="width"
              onChange={onChange}
              value={width}
              isDefault={initialSettings.style.width === width}
            />
          </div>
          <div className="modifier-row">
            <Text className="modifier-row-text" size="xs">
              Height
            </Text>
            <ControlPanel
              settings={settings.controlSettings.height}
              label={"Height"}
              keyProp="height"
              onChange={onChange}
              value={height}
              isDefault={initialSettings.style.height === height}
            />
          </div>
          <div className="modifier-row">
            <Text className="modifier-row-text" size="xs">
              Margin
            </Text>
            <ControlPanel
              settings={settings.controlSettings.margin}
              label={"Margin"}
              keyProp="margin"
              onChange={onChange}
              value={margin}
              isDefault={initialSettings.style.margin === margin}
            />
          </div>
          <div className="modifier-row">
            <Text className="modifier-row-text" size="xs">
              Radius
            </Text>
            <ControlPanel
              settings={settings.controlSettings.borderRadius}
              label={"Border Radius"}
              keyProp="borderRadius"
              onChange={onChange}
              value={borderRadius}
              isDefault={initialSettings.style.borderRadius === borderRadius}
            />
          </div>
          {/* <Accordion defaultValue="customization">
            
            
            
            
          </Accordion> */}
        </div>
      </Popover.Dropdown>
    </Popover>
  );
};
