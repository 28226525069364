import { Text } from "@mantine/core";
import { CenterFocusWeak } from "@mui/icons-material";
import "./NullState.css";

export const NullState = () => {
  return (
    <div className="shadow-viewer-null-state shadow-layer-viewer">
      <div
        className="shadow-viewer-render-window"
        style={{ transform: "scale(0.75) translateY(0px) translateX(0px)" }}
      >
        <div className="shadow-layer-container null-state">
          <div className="shadow-layer-highlight null-state">
            <div className="shadow-layer-render null-state">
              <span className="null-icon">
                <CenterFocusWeak sx={{ fontSize: "45px" }} />
              </span>
              <Text className="null-title">Select a shadow to examine</Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
