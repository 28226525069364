import { shadows } from "../../assets/shadows";
import { CompositeValueDisplay } from "../CompositeValueDisplay/CompositeValueDisplay";
import { ShadowPreviewBox } from "../ShadowPreviewBox/ShadowPreviewBox";
import "./ShadowList.css";

export const ShadowList = ({
  settings,
  onSelect,
  selectedId,
  margin,
  width,
  height,
  borderRadius,
  layers,
}) => {
  const renderShadows = () => {
    return (
      <>
        {shadows.map((shadow, index) => {
          const { value, author } = shadow;
          const _val = value.split(": ")[1];
          const raw = _val.slice(0, _val.length - 1);
          const style = {
            boxShadow: raw,
            width,
            height,
            borderRadius,
            margin,
          };
          return (
            <ShadowPreviewBox
              key={index}
              styleOverrides={style}
              author={author}
              id={index + 1}
              selected={index + 1 === selectedId}
              onSelect={onSelect}
              settings={settings}
              boxShadowValue={raw}
            />
          );
        })}
      </>
    );
  };
  return (
    <div>
      <div className="shadow-list">{renderShadows()}</div>
      <CompositeValueDisplay layers={layers} />
    </div>
  );
};
