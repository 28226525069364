import { useEffect, useState } from "react";
import { ShadowControls } from "../components/ShadowControls/ShadowControls";
import { ShadowList } from "../components/ShadowList/ShadowList";
import { initialSettings } from "../configs";
import "./App.css";
import { cloneDeep } from "lodash";
import { ShadowLayerViewer } from "../components/ShadowLayerViewer/ShadowLayerViewer";
import { Toolbar } from "../components/Toolbar/Toolbar";
import { NullState } from "../components/NullState/NullState";

function App() {
  const initSettings = () => {
    return cloneDeep({
      ...initialSettings,
    });
  };
  const [settings, setSettings] = useState(initSettings());
  const [selectedId, setSelectedId] = useState(undefined);
  const [modified, setModified] = useState(false);

  // State for Preview Element Customization
  const [width, setWidth] = useState(`${initialSettings.style.width}`);
  const [height, setHeight] = useState(`${initialSettings.style.height}`);
  const [borderRadius, setBorderRadius] = useState(
    `${initialSettings.style.borderRadius}`
  );
  const [margin, setMargin] = useState(`${initialSettings.style.margin}`);

  // LayerViewState
  const [layerColor, setLayerColor] = useState(initialSettings.layerColor);
  const [layers, setLayers] = useState([]);

  useEffect(() => {
    if (!selectedId) {
      setLayers([]);
    }
  }, [selectedId]);
  const setStateObj = {
    width: setWidth,
    height: setHeight,
    borderRadius: setBorderRadius,
    margin: setMargin,
  };

  const handleResetSettings = () => {
    for (let prop in setStateObj) {
      setStateObj[prop](`${initialSettings.style[prop]}`);
    }
    setModified(false);
  };

  const handleUpdateSettings = (key, newSettings, subProp) => {
    let updated = { ...settings };

    if (key === "style") {
      setStateObj[subProp](`${newSettings}`);
      setModified(true);
      return;
    }

    updated[key][subProp] = newSettings;

    setModified(true);
    return;
  };

  const handleSelectShadow = (id) => {
    setSelectedId((prev) => {
      if (prev === id) return undefined;
      return id;
    });
  };

  const handleUpdateCopyOption = (val) => {
    const updated = cloneDeep(settings);
    updated.copyOption = val;
    setSettings(updated);
  };

  const handleChangeLayerColor = (newVal) => setLayerColor(newVal);

  return (
    <div className="App">
      <Toolbar
        onChangeCopyType={handleUpdateCopyOption}
        onChangePreview={handleUpdateSettings}
        onChangeLayerColor={handleChangeLayerColor}
        copyType={settings.copyOption}
        layerColor={layerColor}
        onResetAll={handleResetSettings}
        modified={modified}
        settings={settings}
        width={width}
        height={height}
        borderRadius={borderRadius}
        margin={margin}
      />
      <div className="viewport">
        <ShadowList
          settings={settings}
          onSelect={handleSelectShadow}
          selectedId={selectedId}
          width={width}
          height={height}
          borderRadius={borderRadius}
          margin={margin}
          layers={layers}
        />
        <ShadowControls>
          {!selectedId && <NullState />}
          {selectedId && (
            <ShadowLayerViewer
              selectedId={selectedId}
              layerColor={layerColor}
              setLayers={setLayers}
            />
          )}
        </ShadowControls>
      </div>
    </div>
  );
}

export default App;
