export const ShadowLayer = ({
  boxShadow,
  layerId,
  offsetAmount,
  isSingleShadow,
  layerColor,
}) => {
  let offset = 0;
  for (let i = 0; i < layerId; i++) {
    if (isSingleShadow) continue;
    offset += offsetAmount;
  }

  const transform = `scale(0.75) translateY(${offset}px) translateX(${offset}px)`;
  return (
    <div className="shadow-layer-container" style={{ transform }}>
      <span className="shadow-layer-highlight">
        <span className="shadow-layer-title">Layer {layerId}</span>
        <span
          className="shadow-layer-render"
          style={{ boxShadow, background: layerColor }}
        ></span>
      </span>
    </div>
  );
};
