import { Divider } from "@mantine/core";
import { SelectCopyType } from "./components/SelectCopyType/SelectCopyType";
import { SelectLayerColor } from "./components/SelectLayerColor/SelectLayerColor";
import { SelectPreviewModifiers } from "./components/SelectPreviewModifiers/SelectPreviewModifiers";
import "./Toolbar.css";

export const Toolbar = ({
  onChangeCopyType,
  onChangeLayerColor,
  copyType,
  layerColor,
  onChangePreview,
  settings,
  onResetAll,
  modified,
  margin,
  width,
  height,
  borderRadius,
}) => {
  const Dv = () => (
    <Divider
      size="sm"
      sx={{ transform: "scale(0.75)", opacity: 0.15 }}
      orientation="vertical"
    />
  );
  return (
    <div className="toolbar">
      <div className="shadow-list-options">
        <SelectCopyType onChange={onChangeCopyType} value={copyType} />
        <Dv />
        <SelectPreviewModifiers
          onResetAll={onResetAll}
          modified={modified}
          onChange={onChangePreview}
          settings={settings}
          width={width}
          height={height}
          borderRadius={borderRadius}
          margin={margin}
        />
        <Dv />
      </div>
      <div className="shadow-render-options">
        <SelectLayerColor
          layerColor={layerColor}
          onChange={onChangeLayerColor}
        />
      </div>
    </div>
  );
};
