import { CopyButton, Text, Tooltip } from "@mantine/core";

import { CheckCircle, CopyAll } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import "./LayerInspector.css";

export const LayerInspector = ({ layers, compositeValue }) => {
  const renderLayers = () => {
    return (
      <>
        {layers.map((layer, index) => (
          <LayerInputDisplay key={index} layer={layer} layerId={index} />
        ))}
      </>
    );
  };
  return (
    <div className="shadow-layer-inspector-container">{renderLayers()}</div>
  );
};

const LayerInputDisplay = ({ layer, layerId }) => {
  const isRgba = layer.startsWith("rgba(");
  const isRbg = layer.startsWith("rgb(");

  let shadowData = {
    red: "",
    green: "",
    blue: "",
    alpha: 1,
  };
  if (isRgba) {
    const [red, green, blue, alpha] = layer
      .split(") ")[0]
      .slice(isRgba ? 5 : 3)
      .split(", ");
    shadowData = {
      colors: {
        red,
        green,
        blue,
        alpha,
      },
      render: {},
    };
  }

  const renderData = () => {
    const keys = Object.keys(shadowData.colors);

    const { red, green, blue, alpha } = shadowData.colors;

    return (
      <div className="layer-inpector-data-root">
        <LayerId
          layerId={layerId}
          color={`rgba(${red}, ${green}, ${blue}, ${alpha})`}
        />

        <div className="layer-inspector-inputs-container">
          {keys.map((k, i) => (
            <ValueInput label={k} value={shadowData.colors[k]} />
          ))}
        </div>

        <div className="layer-copy-button-container">
          <CopyButton value={layer}>
            {({ copied, copy }) => (
              <Tooltip
                label={`${copied ? "Copied" : "Copy"} Layer ${
                  layerId + 1
                } Shadow`}
                position="bottom"
                withArrow
                color={copied ? "green" : ""}
              >
                <IconButton onClick={copy}>
                  {copied ? (
                    <CheckCircle sx={{ color: "#40c057" }} />
                  ) : (
                    <CopyAll />
                  )}
                </IconButton>
              </Tooltip>
            )}
          </CopyButton>
        </div>
      </div>
    );
  };
  return (
    <div className="layer-inpector-root">
      {/* <div>
        isRgba: {isRgba ? "true" : "false "} {layer}
      </div> */}
      {renderData()}
    </div>
  );
};

const ValueInput = ({ value, label }) => {
  let color = label;
  if (color === "alpha") {
    color = "black";
  }
  return (
    <div className="layer-inspector-input-label-container">
      <Text className="label">{label}</Text>
      <input
        placeholder={label}
        className="layer-inspector-input"
        value={value}
        style={{
          color,
        }}
      />
    </div>
  );
};

const LayerId = ({ layerId, color }) => {
  return (
    <div className="layer-preview-id-container">
      <Tooltip>
        <CopyButton value={color}>
          {({ copied, copy }) => (
            <Tooltip
              label={`${copied ? "Copied" : "Copy"} Color`}
              position="bottom"
              withArrow
              color={copied ? "green" : ""}
            >
              <span
                className="layer-preview-color"
                style={{ backgroundColor: copied ? "#40c057" : color }}
                onClick={copy}
              >
                <span className="layer-preview-icon-container">
                  {copied ? (
                    <CheckCircle sx={{ color: "white" }} />
                  ) : (
                    <CopyAll />
                  )}
                </span>
              </span>
            </Tooltip>
          )}
        </CopyButton>
      </Tooltip>
      <Text className="layer-preview-id-text">Layer {layerId + 1}</Text>
    </div>
  );
};
